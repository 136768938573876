import queryString from 'query-string';

const Approval = () => {
  const query = queryString.parse(window.location.search);
  const gid = query.gid;
  const did = query.did;

  return <div className="App">
    <h1>Approval new lamp</h1>
    <a href={`luvlink://groups/${gid}/${did}`}>Open app</a>
  </div>
}

export default Approval