import { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import axios from "axios";
import queryString from "query-string";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [submitError, setSubmitError] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const query = queryString.parse(window.location.search);
  const token = query.token;

  useEffect(() => {
    if (!_.isEmpty(token)) {
      axios
        .post("/v1/auth/forgot-password/verify", { token })
        .then((response) => {
          console.log(response);
          const { status } = response;

          setValid(status === 200);
          setLoading(false);
        })
        .catch((err) => {
          setErrorMsg(err?.response?.data?.error?.message);
          setLoading(false);
        });
    }
  }, [token]);

  const submit = useCallback(
    (e) => {
      e.preventDefault();
      setSubmitError("");

      if (password !== confirmPassword) {
        setSubmitError("Password mismatched!");
        return;
      }

      axios
        .post("/v1/auth/reset-password", {
          token,
          password,
        })
        .then((response) => {
          if (response.data.error.status === false) {
            setSubmitted(true);
          } else {
            setSubmitError("Cannot reset password! Please try again later!");
          }
        })
        .catch((err) => {
          setSubmitError("Cannot reset password! Please try again later!");
        });
    },
    [password, confirmPassword, token]
  );

  return (
    <div className="App">
      {_.isEmpty(token) ? (
        <h1>
          Token is missing! Please open the link provided in your mailbox.
        </h1>
      ) : loading ? (
        <h1>Loading...</h1>
      ) : !valid ? (
        <h1>{_.isEmpty(errorMsg) ? "Token is expired or used!" : errorMsg}</h1>
      ) : (
        <>
          <h1>Token is valid! Please reset your password.</h1>
          {!submitted ? (
            <div className="form-wrapper">
              <form onSubmit={submit}>
                <label htmlFor="password">New Password</label>
                <input
                  type="password"
                  value={password}
                  id="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <label htmlFor="confirmPassword">Confirm Password</label>
                <input
                  type="password"
                  value={confirmPassword}
                  id="confirmPassword"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {submitError.length > 0 && (
                  <p className="error">{submitError}</p>
                )}
                <input type="submit" value="Submit" />
              </form>
            </div>
          ) : (
            <p>Success! Please login with your new password</p>
          )}
        </>
      )}
    </div>
  );
};

export default ResetPassword;
